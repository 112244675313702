import foundation from 'foundation-sites'
import jQuery from 'jquery'

var responsiveVideo = function () {
  var selectors = '.new-page__wysiwyg iframe, .content-block__article iframe';
  $(selectors).each(function () {
    var iframe = $(this);
    var parent = iframe.closest('.new-page__wysiwyg, .content-block__article');

    if (iframe.attr('width') === '100%') {
      var parentWidth = parent.width();
      iframe.attr('width', parentWidth + 'px');
    }

    iframe.reframe();
  });
}

responsiveVideo();


import 'lazysizes';
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

import 'jquery-placeholder'
import 'jquery-hoverintent'
import 'jquery-modal'
import '../../node_modules/sticky-kit/dist/sticky-kit'
import '../../node_modules/reframe.js/dist/jquery.reframe'

import './_globals'
import './_mobileMenu'
import './_scrollToElement'
import './_slideshow'
//import './_map'
import './_videoPopup'
import './_toggleChildren'
import './_trackEvents'
import './_media'
import './_heroAnimation'
import './_searchCommon';
import './_searchPageSearch';
import './_generalSearch';
import './_videoEmbed';
import './_servicesSlider';
